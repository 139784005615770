<template>
  <div>
    <v-layout align-center>
      <v-autocomplete
        :search-input.sync="search"
        :value="selectedSymptoms"
        :items="symptoms"
        clearable
        :loading="isLoading"
        item-text="name"
        :label="label"
        return-object
        multiple
        chips
        @change="emitInput"
        :hide-no-data="searched"
      >
      </v-autocomplete>
      <v-btn @click="dialog = true" icon small color="secondary">
        <v-icon>add_circle</v-icon>
      </v-btn>
    </v-layout>
    <v-dialog v-model="dialog" :width="600">
      <form-symptom @create="created"></form-symptom>
    </v-dialog>
  </div>
</template>

<script>
import { findByName } from "@/services/symptoms-service";

export default {
  components: {
    "form-symptom": () => import("@/components/Symptoms/Form"),
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    searched() {
      return this.search && this.search.length === 0;
    },
    isLoading() {
      return this.$store.getters["loading/isLoading"];
    },
  },
  data: function () {
    return {
      dialog: false,
      symptoms: [],
      search: "",
      selectedSymptoms: this.value,
    };
  },
  watch: {
    search(value) {
      if (value && value.length > 0) {
        this.filter(value);
      }
    },
    value(value) {
      this.loadValueToItems(value);
    },
  },
  created() {
    this.loadValueToItems(this.value);
  },
  methods: {
    loadValueToItems(value) {
      if (value.length > 0) {
        this.selectedSymptoms = value;
        this.symptoms = value;
      }
    },
    created(createdSymptom) {
      //this.symptoms.push(createdSymptom);
      this.selectedSymptoms.push(createdSymptom);
      this.$emit("input", this.selectedSymptoms);
      this.dialog = false;
    },
    async filter(value) {
      try {
        let response = await findByName(value);
        this.symptoms = this.symptoms.concat(response.data);
      } catch (error) {
        this.$errorHandler(error);
      }
    },
    emitInput(value) {
      this.$emit("input", value);
      this.search = "";
    },
  },
};
</script>

<style></style>
